.App {
  text-align: center;
  /* background: linear-gradient(to right, #ff9a52 50%, #fc9247 50%);  */
  /* background-color: #fcf8eb; */

}

.App-logo {
  /* height: 13vmin; */
  height: 60px;
  pointer-events: none;
}

.Header-bg {
  background-color: '#e4e2dd' !important;
  background-size: 'cover';
  background-position: 'center';
  width: '100%';
  height: '40vh';
}

.App-body {
  /* background-color: #282c34; */
  background-color: #fff;
  /* min-height: 10vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #666;
}

.App-header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  text-align: center;
}

.App-link {
  color: #ed7484;
  cursor: pointer;
}

button {
  cursor: pointer;
  font-size: 1rem;
  padding: 10px 12px;
  border-radius: 5px;
  border: none;
}

.primary-button {
  color: #fff;
  background-color: #ed7484;
}

input[type=text],
select,
input[type=email],
.primary-button {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

input[type=submit] {
  width: 100%;
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* App.css */

/* Styling for the Navbar component */
.navbar {
  background-color: #fcf8eb;
  /* border-bottom: 1.4px solid #ccc;  */

}

.custom-toggler-icon {
  background-color: #ff5733; /* Replace with your desired color code */
  border-radius: 50%; /* Optional: Add border-radius for a rounded background */
}

.navbar-brand {
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
}

.navbar-toggler-icon {
  background-color: white;
  border: none;

}

.navbar-nav .nav-item {
  margin-right: 10px;
}

.nav-link {
  color: white;
  font-weight: bold;
  text-decoration: none;
}

.nav-link:hover {
  color: #ffc107;
}

/* MyCard.css */
.triangular-image {
  overflow: hidden;
  position: relative;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.new-letter{
  text-align: center;

  margin-top: 12vh;
  padding-bottom: 12vh;
  padding-right: 50px;
  padding-left: 50px;
  align-content: "center" 
}

.rotate {
  animation: rotation 2s infinite linear;
  font-size: 100px;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@media (max-width: 576px) {
  .App-body-header {
    /* margin-top: 10vh; */
    min-height: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #666;
  }
  .navbar {
    border-bottom: none; /* Replace with your desired color code */
  
  }

  .new-letter{
    text-align: center;
    margin-top: 12vh;
    padding-bottom: 12vh;
    padding-right: 5;
    padding-left: 5;
    align-content: "center" 
  }
  
}