.radius-btn {
  background-color: "#2e2e2d";
  border-radius: 50;
  color: "#fff";
  font-size: 14;
  padding: 15
}

._col-2-background {
  /* background-color: #fcf8eb; */
  margin-top: 40px;

}

._col-2-div {
  background-size: 'cover';
  background-position: 'center';
  width: '100%';
  /* margin-top: 30px; */
  padding-top: 30px;
}

.btnColor {
  background-color: #f28841e7 !important;
  color: #fff;
  border-radius: 10px;
}

.btnColor:hover {
  background-color: rgba(237, 149, 90, 0.729) !important;
  cursor: pointer;
}

a {
  color: #f26507 !important;
  text-decoration: underline !important;
}

a:hover {
  color: #044703 !important;
  cursor: pointer;
}

.sample_img {
  /* object-fit: 'cover' !important; */
  width: '50%' !important;
  margin-bottom: '500px' !important;
}

@media (max-width: 576px) {

  .sample_img {
    width: '50%' !important;
  }

}