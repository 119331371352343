table {
  width: 100%;
}
div{
  white-space: pre-wrap;
}
.more-td-1-hd {
  font-size: 20px;
  text-align: left;
}

.more-td-2-hd {
  font-size: 20px;
  text-align: left;
}
.more-td-1 {
  font-size: 14px;
  padding-top: 20px;
  text-align: left;
}

.more-td-2 {
  font-size: 14px;
  text-align: left;
}
.col-background {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}

.col-inner {
  width: 80%;
  padding-right: 100px;
  padding-left: 100px;
}

.col-form-input {
  color: "#2e2e2d";
  display: inline-table !important;
  font-size: 14;
  padding: 5px;
  width: 75% !important;
  border-top: 1px solid #2e2e2d !important;
  border-left: 1px solid #2e2e2d !important;
  border-bottom: 1px solid #2e2e2d !important;
  border-right: 0px solid #fff !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  /* border: 1px solid #fff !important; */
  margin: 0%;
  /* border-radius: 20 !important; */
}

.col-chat-input {
  color: "#2e2e2d";
  display: inline-table !important;
  font-size: 14;
  padding: 5px;
  width: 75% !important;
  border-top: 1px solid #038228 !important;
  border-left: 1px solid #038228 !important;
  border-bottom: 1px solid #038228 !important;
  border-right: 0px solid #fff !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  margin: 0%;
  height: 64px;
}

.col-text-area {
  color: "#2e2e2d";
  font-size: 14;
  padding: 5px;
  width: 100% !important;
  border-top: 1px solid #2e2e2d !important;
  border-left: 1px solid #2e2e2d !important;
  border-right: 1px solid #2e2e2d !important;
  border-bottom: 0px solid #fff !important;
  border-radius: 5px;
  padding-bottom: 30px;
  margin: -25px;
  margin-top: 10px;
  overflow: auto;
  /* display: none; */
}

.col-text-p {
  /* color: "#2e2e2d"; */
  font-weight: bolder;
  background-color: #fff !important;
  font-size: 18 !important;
  padding: 10px !important;
  border: 1px solid #038228 !important;
  border-radius: 5px;
  margin-bottom: 30px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  height: 50vh;
  margin-bottom: -9px;
  margin-top: 10px;
  overflow: auto;
  /* display: none; */
}

.col-form-div {
  color: "#2e2e2d";
  font-size: 14;
  border: 1px solid #2e2e2d !important;
  border-radius: 5px !important;
  width: 100%;
  padding: 0;
  padding-top: -50px;
  margin-bottom: 20px;
}

.col-form-select {
  color: "#2e2e2d";
  display: block !important;
  font-size: 14;
  padding: 5px;
  height: 50px;
  border: 1px solid #2e2e2d !important;
  border-radius: 20 !important;
}

.btn-twin-1 {
  background-color: #038228;
  border-radius: 15;
  height: 50px;
  width: 10%;
  color: #fff;
  font-size: 14;
  padding: 10px;
  margin: 0px;
  border: 1px solid #038228;
  border-left: 0px;
  padding-bottom: 52px;
  display: inline-table !important;
  position: relative;
  top: -1px;
}

.btn-twin-2 {
  background-color: #038228;
  border-radius: 15;
  height: 50px;
  width: 15%;
  color: #fff;
  font-size: 14;
  padding: 10px;
  margin: 0px;
  border: 1px solid #038228;
  border-left: 1px solid #d4ffe4;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: inline-table !important;
  padding-bottom: 52px;
  position: relative;
  top: -1px;
}

.btn {
  background-color: #ffdccd;
  border-radius: 15;
  height: 50px;
  width: 25%;
  color: #2e2e2d;
  font-size: 14;
  padding: 10px;
  margin: 0px;
  margin-top: -3px;
  border: 1px solid #2e2e2d;
  border-left: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  display: inline-table !important;
}
.btn:hover {
  background-color: #ffb290;
  border-radius: 15;
  height: 50px;
  width: 25%;
  color: #2e2e2d;
  font-size: 14;
  padding: 10px;
  border: 1px solid #2e2e2d;
  border-left: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  display: inline-table !important;
}

.service-btn {
  background-color: #2da57c;
  border-radius: 15;
  color: #fff;
  font-size: 14;
  padding: 10px;
  /* margin-top: 10px; */
  width: 100%;
  border: none;
}

.service-btn-selected {
  background-color: #2da57c;
  border-radius: 15;
  color: #fff;
  font-size: 14;
  padding: 10px;
  /* margin-top: 10px; */
  width: 100%;
  border: none;
  border-bottom: 1.5px #fff solid;

}

.service-btn:hover {
  border-bottom: 1.5px #666 solid;
}

.works-visible {
  margin-top: 20px;
  display: block;
}

.works-invisible {
  display: none !important;
}

.service-visible {
  display: block;
}

.service-invisible {
  display: none !important;
}

.top-text {
  font-size: calc(10px + 2vmin);
  margin-bottom: 20px;
}

@media (max-width: 576px) {

  .col-text-p {
    text-align: left;
  }
  .col-inner {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
  
  }

  .col-background {
    padding-top: 20px;
  }
  .col-chat-input {
    width: 60% !important;
  }
  .btn-twin-1 {
    width: 20% !important;
  }  
  .btn-twin-2 {
    width: 20% !important;
  }
}

.modal {
  display: block;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.5);
  padding-top: 60px;
}

.modal-content {
  background-color: #fff;
  margin: 5% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
  border-radius: 10px;
  text-align: center;
}
.modal-content-response {
  background-color: #fff;
  margin: 5% auto;
  margin-top: -5% !important;
  padding: 10px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
  border-radius: 10px;
  text-align: center;
}

.close, .close-response {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover, .close-response:hover,
.close:focus, .close-response:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.btn-Success {
  display: inline-block;
  background-color: #7CB928 !important;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  text-decoration: none;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  cursor: pointer;
  font-size: 18 !important;

}

.btn-Danger {
  display: inline-block;
  background-color: #cb2753 !important;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  text-decoration: none;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  cursor: pointer;
  font-size: 18 !important;
}

.text-Success {
  color: #7CB928;
  font-size: 18;
  display: block;
}

.text-Danger {
  color: #cb2753;
  font-size: 18;
  display: block;
}