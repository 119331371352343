

.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
}

.button {
  display: inline-block;
  background-color: #e6be8a !important;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  text-decoration: none;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  transition: all 0.3s ease;
  cursor: pointer;
}

.button:hover {
  background-color: rgb(149, 179, 0);
}

.modal {
  display: block;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.5);
  padding-top: 60px;
}

.modal-content {
  background-color: #fff;
  margin: 5% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
  border-radius: 10px;
  text-align: center;
}
.modal-content-response {
  background-color: #fff;
  margin: 5% auto;
  margin-top: -5% !important;
  padding: 10px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
  border-radius: 10px;
  text-align: center;
}

.close, .close-response {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover, .close-response:hover,
.close:focus, .close-response:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

#base64Output {
  width: 100%;
  height: 100px;
  margin-top: 10px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  resize: none;
}

.firstText {
  color: #7CB928;
  font-size: 16;
  display: block;
}

.btn1 {
  display: inline-block;
  background-color: #7CB928 !important;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  text-decoration: none;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  cursor: pointer;
  font-size: 18 !important;

}
.btn2 {
  display: inline-block;
  background-color: #e6be8a !important;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  text-decoration: none;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  cursor: pointer;
  font-size: 18;

}